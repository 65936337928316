import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../components/SEO'
import Layout from '../components/Layout'

const ImageExhibition = ({ localFile, alt, text }) => {
  return (
    <div className="hidden lg:block w-4/5">
      <Img
        className={text && 'mb-8'}
        fluid={localFile.childImageSharp.fluid}
        alt={alt}
      />
      {text && <small>{text}</small>}
    </div>
  )
}

ImageExhibition.propTypes = {
  localFile: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
}

const ArticleHeadLine = ({ article, setImage, uid }) => {
  const [onFocus, setOnFocus] = useState(false)
  const subTitle = onFocus ? 'Read more' : article.date
  return (
    <li
      className="mb-8"
      onMouseOver={() => {

        if(article.image.localFile){
          setImage(() => ({
            localFile: article.image.localFile,
            alt: article.title,
            text: article.lead.text,
          }))
          setOnFocus(true)
        }
      }}
      onMouseLeave={() => {
        setOnFocus(false)
      }}
    >
      {article.link !== undefined ? (
        <a
          className="link link--underline "
          href={article.link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <h3 className="lg:type-lg mb-2">{article.title}</h3>
          <p className="type-sm">{subTitle}</p>
        </a>
      ) : (
        <Link className="link link--underline " to={`news/` + uid}>
          <h3 className="lg:type-lg mb-2">{article.title}</h3>
          <p className="type-sm">{subTitle}</p>
        </Link>
      )}
    </li>
  )
}

ArticleHeadLine.propTypes = {
  article: PropTypes.object.isRequired,
  setImage: PropTypes.func.isRequired,
}

const NewsPage = ({ data, location }) => {
  const page = data.prismicNewsIndex.data
  const articlesExternal = data.allPrismicNewsArticle.edges
  const articlesInternal = data.allPrismicNewsInternalArticle.edges

  const articles = [...articlesExternal, ...articlesInternal]
  articles.sort(function(a, b) {
    return new Date(b.node.data.date) - new Date(a.node.data.date)
  })

  const [image, setImage] = useState({
    localFile: articles[0].node.data.image.localFile,
    alt: articles[0].node.data.title,
    text: articles[0].node.data.lead.text,
  })

  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <div className="pt-32 lg:pt-36 mb-0 lg:mb-0">
        <div className="relative">
          <div className="lg:absolute w-1/2 top-0 left-0 h-full pl-2/48 lg:pl-1/48 pr-2/48">
            <div className="lg:sticker">
              <div className="mb-6 lg:mb-12">
                <h1 className="text-lg lg:type-3xl text-white">{page.title}</h1>
              </div>

              <ImageExhibition
                localFile={image.localFile}
                alt={image.alt}
                text={image.text}
              />
            </div>
          </div>

          <div className="grid">
            <div className="grid__half--secondary pt-4">
              <ul>
                {articles.map(({ node }, i) => (
                  <ArticleHeadLine
                    key={i}
                    article={node.data}
                    setImage={setImage}
                    uid={node.uid && node.uid}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

NewsPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageNewsQuery = graphql`
  query {
    prismicNewsIndex {
      data {
        title
        template_name {
          text
        }
        meta_title
        meta_description
      }
    }
    allPrismicNewsInternalArticle(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          uid
          data {
            title
            date(formatString: "D MMMM YYYY")
            lead {
              html
              text
            }
            image {
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1040) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicNewsArticle(sort: { fields: data___date, order: DESC }) {
      edges {
        node {
          data {
            title
            date(formatString: "D MMMM YYYY")
            lead {
              html
              text
            }
            image {
              url
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1040) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            link {
              url
            }
          }
        }
      }
    }
  }
`

export default NewsPage
